<template>
  <div>
    <div
      class="u-display-flex u-height-100 u-position-relative u-flex-align-items-center"
    >
      <div class="u-flex-direction-column">
        <div
          v-if="title"
          class="u-font-size-5 u-font-weight-600 u-spacing-pv-m"
        >
          {{ title }}
        </div>
        <div
          class="u-border-right u-border-color-grey-xxx-light u-border-width-s u-spacing-pr-xxl"
        >
          <div
            v-for="(item, index) in radioConfig"
            :key="item.value"
            :class="[index != radioConfig.length - 1 ? 'u-spacing-pb-m' : '']"
          >
            <rb-radio
              v-model="radioValue"
              :native-value="item.value"
              class="u-font-size-5 u-width-100 u-spacing-mr-s"
              @input="onRadioUpdate"
            >
              <div>
                {{ item.title }}
              </div>
            </rb-radio>
            <div
              v-if="item.description"
              class="u-max-width-320px u-font-size-5 u-spacing-ph-l u-spacing-pt-s u-color-grey-lighter"
            >
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="u-display-flex u-flex-direction-column u-spacing-ph-xxl">
        <div
          v-if="showInputValue && radioConfigObj.type === 'input'"
          class="u-height-100 u-flex-direction-row u-flex-justify-content-center u-display-flex u-spacing-mb-m"
          :class="[title && 'u-spacing-pt-xxl']"
        >
          <div
            v-if="inputValue"
            class="u-display-flex"
          >
            <div
              v-for="(keyName, index) in Object.keys(inputValue)"
              :key="keyName + index"
              :class="{ 'u-spacing-mr-l': !index }"
            >
              <rb-checkbox
                v-model="inputValue[keyName].selected"
                class="u-font-size-5 u-width-100 u-spacing-pb-s u-flex-direction-row"
              >
                <div>{{ inputValue[keyName].label }}</div>
              </rb-checkbox>
              <div class="u-display-flex">
                <span
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  :class="{
                    'u-bg-color-grey-xxx-light': !inputValue[keyName].selected
                  }"
                >
                  {{ $currency }}
                </span>
                <input
                  v-model="inputValue[keyName].value"
                  :disabled="!inputValue[keyName].selected"
                  class="rb-input currency-input u-display-flex u-flex-align-items-center"
                  step="0.01"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="radioConfigObj.type === 'dropdown'"
          class="u-height-100 u-display-flex u-flex-direction-column u-flex-justify-content-center u-spacing-mb-m"
          :class="[title && 'u-spacing-pt-xxl']"
        >
          <div class="u-display-flex">
            <div class="u-max-width-200px">
              <rb-select
                class="valueDropdown"
                :send-details="true"
                :on-close="optionSelected"
                :options="radioConfigObj && radioConfigObj.dropdown"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-text-overflow-ellipsis u-flex-direction-column u-spacing-p-s u-font-size-5"
                  >
                    {{ selectedDropdown && selectedDropdown.label }}
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div class="u-display-flex u-flex-align-items-center">
                    {{ option.label }}
                  </div>
                </template>
              </rb-select>
            </div>
            <div
              v-if="selectedDropdown && selectedDropdown.value"
              class="u-spacing-ph-m"
            >
              <div
                class="u-display-flex u-flex-0 u-flex-align-items-center u-width-20"
                :class="{ percentBox: selectedDropdown.value === 'percentage' }"
              >
                <span
                  v-if="selectedDropdown.value !== 'percentage'"
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                >
                  {{ $currency }}
                </span>
                <input
                  v-model="updatedValue"
                  type="number"
                  :name="selectedAttribute && selectedAttribute.value"
                  class="rb-input currency-input rb-width u-display-flex u-flex-align-items-center"
                  step="0.01"
                  @input="onInputChange"
                />
                <span
                  v-if="selectedDropdown.value === 'percentage'"
                  class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
                >
                  %
                </span>
              </div>
            </div>
            <rb-select
              v-if="attributeDropdownOptions"
              class="valueDropdown"
              :send-details="true"
              :on-close="attributeSelected"
              :options="attributeDropdownOptions"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
              >
                <div
                  class="u-display-flex u-text-overflow-ellipsis u-flex-direction-column u-spacing-p-s u-font-size-5"
                >
                  {{ (selectedAttribute || {}).label }}
                </div>
                <rb-icon
                  class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                  :icon="'caret-down'"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.label }}
                </div>
              </template>
            </rb-select>
          </div>
        </div>
        <!-- Error Message -->
        <div
          v-if="showError"
          class="errorMessage u-font-size-7 u-color-red-base u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small"
            :icon="'error-fill'"
          />
          <div class="u-flex-0 u-spacing-ml-xxs u-spacing-mt-xxxs">
            {{ showError }}
          </div>
        </div>
      </div>
    </div>
    <div class="u-spacing-pt-xl">
      <footerButtons
        :button-min-width="'u-min-width-100px'"
        :disable-left-btn="disableLeftButton"
        :left-button-type="disableLeftButton ? 'filled' : 'hollow'"
        @onClickBtnRight="onCancel"
        @onClickBtnLeft="onSave"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
export default {
  components: {
    footerButtons
  },
  props: {
    title: {
      type: String,
      default: null
    },
    defaultRadioSelected: {
      type: String,
      default: 'test'
    },
    showInputValue: {
      type: Boolean,
      default: true
    },
    radioConfig: {
      type: Array,
      default: () => {
        return [
          {
            title: 'Test',
            value: 'test',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Test1',
            value: 'test1',
            type: 'input'
          }
        ];
      }
    },
    attributeDropdownOptions: {
      type: Array,
      default: () => {
        return [
          {
            label: 'Total Budget',
            value: ':oldTotalBudget'
          },
          {
            label: 'Daily Budget',
            value: ':oldDailyBudget'
          }
        ];
      }
    },
    defaultInputValue: {
      type: Object,
      default: () => {
        return {};
      }
    },
    validation: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      selectedDropdown: {},
      radioValue: null,
      inputValue: null,
      selectedAttribute: {},
      updatedValue: null
    };
  },
  computed: {
    radioConfigObj() {
      const radioObj = this.radioConfig.filter(
        (item) => item.value === this.radioValue
      );
      return radioObj.length && radioObj[0];
    },
    disableLeftButton() {
      if (this.showError) {
        return true;
      }
      let inputValue = this.inputValue;
      inputValue = Object.keys(this.inputValue).map((key) => ({
        [key]: parseFloat(this.inputValue?.[key]?.value)
      }));
      let defaultInputValue = this.defaultInputValue;
      defaultInputValue = Object.keys(this.defaultInputValue).map((key) => ({
        [key]: parseFloat(this.defaultInputValue?.[key]?.value)
      }));
      if (!isEqual(inputValue, defaultInputValue)) {
        return false;
      }
      return true;
    },
    showError() {
      let error;
      if (this.inputValue) {
        error =
          !Object.values(this.inputValue).some((item) =>
            Boolean(Number(item.value))
          ) && this.validation?.genericErrorText?.text;
        if (!error) {
          for (const key in this.inputValue) {
            if (
              parseFloat(this.inputValue[key].value) <
              parseFloat(this.validation[key].min)
            ) {
              error = this.validation[key].text;
              break;
            }
          }
        }
        return error;
      }
    }
  },
  watch: {
    radioConfigObj(newValue) {
      if (this.radioConfigObj.type === 'dropdown') {
        this.selectedDropdown =
          (this.radioConfigObj?.dropdown && this.radioConfigObj?.dropdown[0]) ||
          {};
      }
    }
  },
  created() {
    this.radioValue = this.defaultRadioSelected;
    this.inputValue = cloneDeep(this.defaultInputValue);
    this.selectedAttribute = this.attributeDropdownOptions[0];
  },
  methods: {
    optionSelected(c, selectedOption) {
      if (selectedOption?.length) {
        this.selectedDropdown = selectedOption[0];
        this.onSelectDropDownChange();
      }
    },
    attributeSelected(c, selectedOption) {
      if (selectedOption?.length) {
        this.selectedAttribute = selectedOption[0];
        const inputValue = cloneDeep(this.inputValue);
        const defaultInputValue = cloneDeep(this.defaultInputValue);
        const selectedValue = selectedOption[0].value;
        Object.keys(inputValue).forEach((key) => {
          if (key === selectedValue || selectedValue === 'both') {
            inputValue[key].selected = true;
          } else {
            inputValue[key] = { ...defaultInputValue[key], selected: false };
          }
        });
        this.inputValue = inputValue;
        if (selectedValue === 'both') {
          Object.keys(inputValue).forEach((key) => {
            this.updateInputValue(key);
          });
        } else {
          this.updateInputValue(selectedValue);
        }
      }
    },
    updateInputValue(selectedValue) {
      const inputValue = cloneDeep(this.inputValue);
      this.onUpdate({
        target: {
          name: selectedValue,
          value: this.updatedValue || inputValue[selectedValue].value
        }
      });
    },
    onRadioUpdate() {
      this.inputValue = cloneDeep(this.defaultInputValue);
      this.selectedAttribute = this.attributeDropdownOptions[0];
      this.updatedValue = null;
    },
    onInputChange(event) {
      const { name } = event.target;
      if (name !== 'both') {
        this.onUpdate(event);
      } else {
        Object.keys(this.inputValue).forEach((key) => {
          this.updateInputValue(key);
        });
      }
    },
    onUpdate(event) {
      const { name } = event.target;
      const oldVal = this.defaultInputValue[name].value;
      let delta = parseFloat(this.updatedValue);
      if (!delta) {
        this.inputValue[name].value = oldVal;
        return;
      }
      if (this.radioValue === 'set_new_budget') {
        this.inputValue[name].value = delta;
        return;
      }
      if (this.selectedDropdown?.value === 'percentage') {
        delta = oldVal * (delta / 100);
      }
      if (this.radioValue === 'increase_budget') {
        this.inputValue[name].value = parseFloat((oldVal + delta).toFixed(2));
      } else {
        this.inputValue[name].value = parseFloat((oldVal - delta).toFixed(2));
      }
    },
    onSelectDropDownChange() {
      if (this.selectedAttribute.value === 'both') {
        Object.keys(this.inputValue).forEach((key) => {
          this.updateInputValue(key);
        });
      } else {
        this.updateInputValue(this.selectedAttribute.value);
      }
    },
    onSave() {
      const inputValue = this.inputValue;
      const defaultInputValue = this.defaultInputValue;
      this.$nextTick(() => {
        const updatedValues = Object.keys(inputValue).reduce((acc, key) => {
          acc[key] = defaultInputValue[key].value;
          const newKey = key.replace('old', 'new');
          acc[newKey] = inputValue[key].value;
          return acc;
        }, {});

        this.$emit('onSave', updatedValues);
      });
    },
    onCancel() {
      this.$emit('onCancel');
    }
  }
};
</script>

<style lang="css" scoped>
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}

.rb-width {
  width: 48px;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.rb-input:disabled {
  background-color: #e9eaeb;
}
</style>
