<template>
  <!-- Desc: budget and bid component for campaign budget change and adgroup bid change tab -->
  <div class="card u-spacing-p-l">
    <loader
      v-show="entityInfo.status === 'loading'"
      class="fill--parent"
      :loading="entityInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <div v-if="!adjustBudget && budget">
      <div class="u-spacing-pb-l">
        <rb-button
          :text="'Adjust Budget'"
          type="filled"
          :click-fn="onAdjustBudget"
          class="u-min-width-100px"
        />
      </div>
      <div class="u-display-flex u-flex-align-items-center">
        <div class="u-spacing-mr-l">
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            {{ config && config.title1 }}:
          </div>
          <div class="u-position-relative">
            <rb-input
              v-model="budget[':oldTotalBudget'].value"
              class="u-display-flex u-width-100px u-flex-align-items-center"
              :disabled="true"
            />
            <div
              class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
            >
              {{ currency }}
            </div>
          </div>
        </div>
        <div>
          <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
            {{ config && config.title2 }}:
          </div>
          <div class="u-position-relative">
            <rb-input
              v-model="budget[':oldDailyBudget'].value"
              class="u-display-flex u-width-100px u-flex-align-items-center"
              :disabled="true"
            />
            <div
              class="u-font-size-5 u-color-grey-lighter value-left u-position-absolute"
            >
              {{ currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="adjustBudget && initialBudgetSet"
      class="u-display-flex u-flex-direction-column u-height-100"
    >
      <div class="u-width-260px">
        <div
          class="u-spacing-pb-s u-font-size-5 u-font-weight-600 u-text-case-upper"
        >
          {{ (config && config.adjustTitle) || 'Adjust Budget:' }}
        </div>
        <div :key="key">
          <radioSelectionWithInput
            class="u-spacing-pt-s"
            :radio-config="dailyBudgetConfig"
            :default-radio-selected="lifetimeBudgetRadioDefault"
            :default-input-value="initialBudgetSet"
            :attribute-dropdown-options="attributeDropdownOptions"
            :validation="config && config.validation"
            @onSave="onSave"
            @onCancel="onCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import loader from '@/components/basic/loader';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import radioSelectionWithInput from '@/pages/entity_details/configs/walmart/components/radioSelectionWithInput.vue';

export default {
  components: {
    loader,
    radioSelectionWithInput
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      initialBudgetSet: null,
      reqMetadataMap: null,
      radioValue: null,
      isAdgroup: false,
      lifetimeBudgetRadioDefault: 'set_new_budget',
      config: null,
      getState: null,
      requestPayload: null,
      updateMetadata: null,
      adjustBudget: false,
      budget: null,
      currency: Vue.prototype.$currency,
      tabConfig: null,
      key: 1
    };
  },
  computed: {
    dailyBudgetConfig() {
      const type = this?.config?.type || 'budget';
      return [
        {
          title: `Set new ${type}`,
          type: 'input',
          value: 'set_new_budget'
        },
        {
          title: `Increase ${type}`,
          value: 'increase_budget',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: `Decrease ${type}`,
          value: 'decrease_budget',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ];
    },
    entityInfo() {
      return this.$store.getters[this.getState];
    },
    attributeDropdownOptions() {
      const toAppend = {
        value: 'both',
        label: this.config?.title3
      };
      return [
        ...Object.keys(this.budget).map((key) => ({
          ...this.budget[key],
          value: key
        })),
        toAppend
      ];
    }
  },
  watch: {
    entityInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setBudget();
        }
      },
      immediate: true
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.reqMetadataMap = tabConfig?.reqMetadataMap;
    this.isAdgroup = tabConfig?.isAdgroup || false;
    this.config = tabConfig?.config;
  },
  methods: {
    onCancel() {
      this.setBudget();
    },
    onSave(result) {
      const reqPayload = this.formatRequestPayload(result);
      // todo update payload for API

      let entityName = null;
      if (this.tabMetadata.component === 'adgroupDefaultBid') {
        entityName = {
          singular: 'Adgroup Bid',
          plural: 'Adgroup Bids',
          noCount: true
        };
      } else {
        entityName = {
          singular: 'Campaign Budget',
          plural: 'Campaign Budget',
          noCount: true
        };
      }
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [reqPayload],
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        callAdgroupFetch: this.isAdgroup,
        snackbar: this.$snackbar,
        entityName: entityName,
        routeData: this.$route
      });
      this.setBudget();
    },

    formatRequestPayload(result) {
      const reqPayload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':name': this.entityInfo?.data?.name,
        ':entityId': this.entityId,
        ':campaignId': this[this.reqMetadataMap[':campaignId']],
        ':pageUrl': window.location.href,
        ':advertiserId': this?.$route?.query?.advertiser_id,
        ...result
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(reqPayload, item, reqValues[item]);
      }
      return reqPayload;
    },
    onAdjustBudget() {
      this.adjustBudget = true;
    },
    setBudget() {
      this.initialBudgetSet = {
        ':oldTotalBudget': {
          selected: true,
          label: this.config?.title1,
          value:
            this.entityInfo?.data[this.reqMetadataMap[':oldTotalBudget']] ||
            null
        },
        ':oldDailyBudget': {
          selected: false,
          label: this.config?.title2,
          value:
            this.entityInfo?.data[this.reqMetadataMap[':oldDailyBudget']] ||
            null
        }
      };
      this.budget = { ...this.initialBudgetSet };
      this.adjustBudget = false;
      this.radioValue = null;
      this.key++;
    }
  }
};
</script>
<style scoped>
.min-height-card {
  min-height: 630px;
}
.value-left {
  left: 8px;
  top: 12px;
}
.value-right {
  right: 8px;
  top: 12px;
}
</style>
